import { PotentialApiClient } from '@IJGN/potential'

const useHttps = import.meta.env.VITE_API_HTTPS === 'true'
const protocol = useHttps ? 'https' : 'http'
const host = import.meta.env.VITE_API_HOST
const version = import.meta.env.VITE_API_VERSION
export const API_BASE_URL = `${protocol}://${host}/${version}`
export const apiKey = import.meta.env.VITE_API_KEY

// シングルトンインスタンスを管理する変数
let apiClientInstance: PotentialApiClient | null = null

/**
 * ApiClient のインスタンスを返すシングルトン関数
 * @returns ApiClient インスタンス
 */
export const getApiClient = (): PotentialApiClient => {
  if (!apiClientInstance) {
    // 初期化時に一度だけインスタンスを生成
    apiClientInstance = new PotentialApiClient({
      apiUrlMappings: apiUrlMappings,
      apiKey,
    })
  }

  return apiClientInstance
}

/**
 * APIエンドポイントとベースURLのマッピング
 *
 * @type {[string, string][]}
 * @example
 * [
 *  ['/password/**', 'https://api.id.potential-cloud.jp'],
 *  ['/**', API_BASE_URL],
 * ]
 */
const apiUrlMappings: [string, string][] = [['/**', API_BASE_URL]]
